<template>
  <app-modal
    :title="title"
    v-model="open"
    confirm-label="Save Changes"
    valid
    @confirmed="handleChanges"
  >
    <template v-slot:title>
      <div
        v-if="user"
        class="xflex xw-fit xflex-row xgap-[10px] xrounded-lg xjustify-start xitems-center"
      >
        <avatar :user="user"></avatar>
        <div class="xflex xflex-col xjustify-start xitems-start">
          <h1 class="xfont-semibold xleading-[20px]">{{ user.real_name }}</h1>
          <h2 class="xtext-[13px] xfont-bold xleading-[20px]">{{ user.job_title }}</h2>
        </div>
      </div>
    </template>
    <div class="xflex xflex-col xjustify-center xw-full xmin-h-full xgap-y-4">
      <v-card
        flat
        v-if="user"
        rounded="20"
        class="xpy-[10px] xpx-[10px] xgap-[10px] xflex md:xflex-row xflex-col xjustify-between xitems-center"
      >
        <v-sheet
          class="xflex-1 xjustify-center xitems-center xtext-center md:xw-10/12 xw-full"
        >
          <div
            class="xflex xjustify-between md:xflex-row xflex-col xw-full xitems-center"
          >
            <span class="xfont-semibold xtext-gray-600">
              Assign or remove roles
            </span>
            <div>
              <v-icon small color="green" left> mdi-alert-box </v-icon>
              <small>Auto assign by system base on user roles on Slack</small>
            </div>
          </div>
          <v-chip-group
            mandatory
            show-arrows
            class="xw-full"
            multiple
            v-model="user_roles"
            active-class="primary"
          >
            <v-chip
              :disabled="!role.assignable"
              v-for="role in workspaceRoles"
              :value="role.id"
              :key="role.id"
              label
            >
              <v-icon small color="green" left v-if="!role.assignable">
                mdi-alert-box
              </v-icon>
              {{ role.normalize_name }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </v-card>
      <v-card color="transparent" flat v-if="user" :key="user.id">
        <app-table :headings="headers" :items="items" :loading="false">
          <template v-slot:permission="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="xfont-[600]">
                  {{ item.normalize_name | ucwords }}
                </span>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
          </template>
          <template
            v-for="role in selectedRoles"
            v-slot:[role.normalize_name]="{ item }"
          >
            <v-icon
              class="xmx-auto"
              :key="`${role.id}-${item.id}`"
              v-if="grantedByRole(item.id, role.id)"
              color="primary"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              :key="`${role.id}-${item.id}_1`"
              v-else
              class="xmx-auto"
              color="error"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:direct="{ item }">
            <span v-if="grantedByRoles(item.id)">Granted by role</span>
            <v-switch
              v-else
              inset color="#19CB40"
              :value="item.id"
              v-model="direct_permissions"
            ></v-switch>
          </template>
        </app-table>
      </v-card>
    </div>
  </app-modal>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    user: { type: Object, default: undefined },
  },
  data() {
    return {
      open: false,
      direct_permissions: [],
      user_roles: [],
    };
  },
  computed: {
    ...mapGetters("roles", ["workspaceRoles", "appPermissions"]),
    title() {
      return this.user
        ? `${this.user.real_name} Roles and Permissions`
        : "Roles and Permissions";
    },
    selectedRoles() {
      return this.workspaceRoles.filter((i) => this.user_roles.includes(i.id));
    },
    headers() {
      let list = [{ text: "Permissions", value: "permission", sortable: true }];
      if (this.user) {
        this.selectedRoles.forEach((i) => {
          list.push({
            text: i.normalize_name,
            value: i.normalize_name,
            sortable: false,
            align: "center",
            tdClass: "xtext-center",
          });
        });
      }
      list.push({ text: "Grant Permission", value: "direct", sortable: false });
      return list;
    },
    items() {
      return this.appPermissions.map((i) => {
        i.granted_by_direct = this.direct_permissions.includes(i.id);
        return i;
      });
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
        if (!val)
          this.direct_permissions = this.removed_roles = this.added_roles = [];
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    user: {
      handler: function (val) {
        val && this.fetchUserData(val.id);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchUserData(id) {
      this.$axios.get(`api/user/${id}/roles-permissions`).then(({ data }) => {
        this.direct_permissions = data.direct_permissions;
        this.user_roles = data.roles;
      });
    },
    handleChanges() {
      this.$emit("changes", {
        user_id: this.user.id,
        direct_permissions: this.direct_permissions,
        user_roles: this.selectedRoles.map((i) => i.id),
      });
    },
    grantedByRole(permission_id, role_id) {
      let role = this.workspaceRoles.find((i) => i.id === role_id);
      if (!role) return false;
      return ~role.permissions.findIndex((j) => j.id === permission_id)
        ? true
        : false;
    },
    grantedByRoles(permission_id) {
      let roles = _.cloneDeep(this.selectedRoles);
      if (!roles.length) return false;
      let permissions = roles.map((k) => k.permissions.map((l) => l.id));
      return ~_.flatten(permissions).findIndex((j) => j === permission_id)
        ? true
        : false;
    },
  },
};
</script>

<style scoped></style>
