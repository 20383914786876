<template>
  <app-modal
    :title="title"
    :valid="valid"
    v-model="open"
    max-width="800"
    @confirmed="handleConfirm"
  >
    <v-card flat color="transparent">
      <v-card-text>
        <v-alert color="accent" v-if="role && !role.editable"> 
          This role is a system role and are not editable. Only the permissions part are allowed to
          update
        </v-alert>
        <div class="xflex xflex-col xjustify-between xitems-start">
          <div class="xfont-bold xtext-[15px]">Role Name:</div>
          <app-input
            v-model="name"
            :disabled="role && !role.editable"
            :rules="[requiredRule(), notInRule()]"
            class="xw-full xmx-auto xm-[1em]"
          ></app-input>
        </div>
        <div class="xflex xflex-col xmt-[5px] xjustify-between xitems-start">
          <div class="xfont-bold xtext-[15px]">Role Description:</div>
          <app-textarea
            v-model="description"
            :rows="2"
            :disabled="role && !role.editable"
            class="xw-full xmx-auto xm-[1em]"
          ></app-textarea>
        </div>
        <div class="xflex xflex-col xmt-[10px] xjustify-between xitems-start">
          <div class="xfont-bold xtext-[15px]">Role Permissions:</div>
          <div
            v-for="perm in appPermissions"
            :key="perm.id"
            class="xflex xw-full xborder-b-[1px] xborder-[#c9cbce] xpy-[4px] xflex-row xflex-nowrap xjustify-between xitems-center"
          >
            <div class="xflex xflex-col xflex-1">
              <h1 class="xfont-semibold">
                {{ perm.normalize_name | ucwords }}
              </h1>
              <small class="xtext-[12px]">{{ perm.description }}</small>
            </div>
            <div class="xflex xflex-col xjustify-center xitems-center">
              <v-switch v-model="permissions" :value="perm.id" color="#19CB40" inset></v-switch>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </app-modal>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import SelectMultiple from "@/views/GlobalComponents/Forms/SelectMultiple.vue";

export default {
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    role: { type: Object, default: undefined },
  },
  data() {
    return {
      open: false,
      name: null,
      description: null,
      permissions: [],
      reservedNames: ["super admin"],
    };
  },
  computed: {
    ...mapGetters("roles", ["appPermissions", "workspaceRoles"]),
    title() {
      return this.role ? `Edit ${this.role.normalize_name}` : "Add New Role";
    },
    isEdit() {
      return this.role ? true : false;
    },
    valid() {
      return this.name && this.permissions.length ? true : false;
    },
    existingNames() {
      const list = _.cloneDeep(this.workspaceRoles).map((i) =>
        i.normalize_name.toLowerCase()
      );
      list.push(...this.reservedNames);
      return list;
    },
    payload() {
      return {
        id: this.isEdit ? this.role.id : null,
        name: this.name,
        permissions: this.permissions,
        description: this.description,
      };
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    role: {
      handler: function (val) {
        this.mapItem(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    notInRule(v) {
      if (this.isEdit) return true;
      return (v) =>
        (!!v && !this.existingNames.includes(v.toLowerCase())) ||
        `Role name (${this.$options.filters.ucwords(v)}) already exists`;
    },
    handleConfirm() {
      this.$emit(this.isEdit ? "update" : "save", this.payload);
    },
    mapItem(obj) {
      this.permissions = obj ? obj.permissions.map((i) => i.id) : [];
      this.name = obj ? obj.normalize_name : null;
      this.description = obj ? obj.description : null;
    },
  },
  components: { SelectMultiple },
};
</script>

<style scoped></style>
