<template>
  <v-select
    hide-details="auto"
    dense
    multiple
    v-model="selected"
    outlined
    append-icon="mdi-chevron-down"
    :item-text="itemText"
    :item-value="itemValue"
    v-bind="$attrs"
    :items="selection"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip
        color="base-100"
        label
        small
        class="mr-auto my-1"
        v-if="index < limit"
      >
        <span>{{ item[itemText] }}</span>
      </v-chip>
      <v-chip
        label
        small
        color="primary"
        v-if="index === limit && limit != items.length"
        class="white--text text-caption"
      >
        +{{ selected.length - limit }} Others
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  props: {
    itemText: { type: String, default: () => "name" },
    itemValue: { type: String, default: () => "id" },
    items: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
    displayCount: { type: Number, default: 3 }, //0 means all all selected
  },
  data() {
    return {
      limit: 0,
      selection: [],
      selected: [],
    };
  },
  watch: {
    displayCount: {
      handler: function (val) {
        this.limit = val === 0 ? this.items.length : val;
      },
      immediate: true,
    },
    items: {
      handler: function (val) {
        this.selection = _.cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler: function (val) {
        this.selected = val;
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    selectedAll() {
      return this.selected.length === this.selection.length;
    },
    selectedSome() {
      return this.selected.length > 0 && !this.selectedAll;
    },
    icon() {
      if (this.selectedAll) return "mdi-close-box";
      if (this.selectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.selected = [];
        } else {
          this.selected = _.cloneDeep(this.selection).map(
            (i) => i[this.itemValue]
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
