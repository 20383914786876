var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('AdminOwners',{attrs:{"has-edit-roles":""},on:{"edit-roles":function($event){return _vm.updateUser(true, $event)}}}),_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xgap-2 xitems-center"},[_c('app-search',{staticClass:"md:xmax-w-[300px] xmax-w-full",attrs:{"api":("api/workspaces/" + (_vm.user.team_id) + "/users-search?with=roles"),"item-text":"real_name","item-value":"id"},on:{"selected":_vm.onSelect,"cleared":_vm.fetchUsers}}),_c('div',{staticClass:"xflex xjustify-end xgap-2 xitems-center",class:[
        _vm.tabletDown ? 'xw-full' : '',
        _vm.mobile ? 'xflex-col' : 'xflex-row' ]},[(_vm.user)?_c('SyncToSlack',{attrs:{"block":_vm.smDown,"team-id":_vm.user.team_id}}):_vm._e(),(_vm.$can.and('add_roles'))?_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","block":_vm.smDown,"depressed":""},on:{"click":function($event){return _vm.updateRole(true, null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Role ")],1):_vm._e(),(_vm.$can.and('update_roles'))?_c('btn-list',{attrs:{"elevation":"0","block":_vm.smDown,"label":"Manage Roles","depressed":"","btn-color":"primary"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"dense":"","max-height":"400"}},_vm._l((_vm.workspaceRoles),function(roleW){return _c('v-list-item',{key:roleW.id,on:{"click":function($event){return _vm.updateRole(true, roleW)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(roleW.normalize_name)+" ")])],1)}),1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('app-table',{staticClass:"xmin-h-full",attrs:{"headings":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"xflex xflex-row xjustify-center xitems-center xgap-2"},[(_vm.$can.or('assign_roles|revoke_roles'))?_c('btn-tooltip',{attrs:{"tip":"Edit Roles","small":"","color":"primary"},on:{"click":function($event){return _vm.updateUser(true, item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-cog")]),_vm._v(" Update Roles & Permissions ")],1):_vm._e()],1)]}},{key:"user",fn:function(ref){
      var item = ref.item;
return [(item)?_c('router-link',{attrs:{"to":{
            name: 'app-user-record-monthly',
            params: {
              id: item.id,
              year_month: _vm.yearMonth,
            },
          }}},[_c('avatar',{attrs:{"size":"40","user":item,"with-name":""}})],1):_vm._e()]}},{key:"roles",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"xflex md:xflex-row xflex-col xmy-[1px] xgap-[2px]"},_vm._l((item.roles),function(role1){return _c('v-chip',{key:role1.id,attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(role1.normalize_name)+" ")])}),1)]}}])})],1),_c('UserRolePermissionModal',{attrs:{"user":_vm.selectedUser},on:{"changes":_vm.handleChanges},model:{value:(_vm.userModal),callback:function ($$v) {_vm.userModal=$$v},expression:"userModal"}}),_c('CustomRoleModal',{attrs:{"role":_vm.selectedRole},on:{"update":_vm.handleUpdateRole,"save":_vm.handleSaveRole},model:{value:(_vm.roleModal),callback:function ($$v) {_vm.roleModal=$$v},expression:"roleModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }